.profile-thumb {
  -ms-border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 0 auto 30px;
  position: relative;
  background-size: cover !important;
}

.social-icons li {
  display: -moz-inline-stack;
  margin: 0 10px;
  list-style: none;
  display: inline-block;
}

.social-icons li a {
  display: -moz-inline-stack;
  text-decoration: none;
  display: inline;
}
/*# sourceMappingURL=index.d1b7ee9f.css.map */
