.profile-thumb {
  background-size: cover !important;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  height: 200px;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.social-icons li {
  list-style: none;
  display: -moz-inline-stack;
  display: inline-block;
  margin: 0 10px;
}

.social-icons li a {
  display: -moz-inline-stack;
  display: inline-block;
  display: inline;
  text-decoration: none;
}

.social-icons li a:hover {
  
  
}

